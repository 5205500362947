@import '@styles';

.FoodSummaryChart {
    position: relative;
    $root: &;

    > svg {
        width: 100%;
        height: 100%;
    }

    path {
        transition: d 500ms;
    }

    &__axis {
        pointer-events: none;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 16px;

        @include media-max(1400px) {
            font-size: 12px;
        }
    }

    &__curve {
        position: relative;
        z-index: 2;
    }

    &__area {
        position: relative;
        z-index: 1;
    }

    &__dots {
        position: absolute;
    }

    &__prediction-thumb-container {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        transition: transform 300ms, height 300ms;
        width: 100px;
        cursor: pointer;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding-right: 20px;
        font-family: 'Montserrat', sans-serif;
        pointer-events: none;

        &:hover {
            #{$root}__prediction-thumb-title:after {
                transform: translate(5px, -50%);
            }
        }
    }

    &__prediction-thumb-title {
        position: relative;
        font-size: 20px;
        line-height: 24px;
        color: transparent;
        -webkit-background-clip: text;
        white-space: nowrap;
        animation: text-gradient 2.5s linear infinite;
        background-image: linear-gradient(to left, #fff 30%, #00EBF5 50%, #fff 70%);
        background-size: 400px 400px;
    }
    &__prediction-thumb-sub-title {
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
        font-weight: 900;
    }

    &__tooltip {
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 300ms 200ms;

        &.is-shown {
            opacity: 1;
            transition: opacity 200ms 0s;
        }
    }

    &__future-label {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        color: #fff;
        font-size: 14px;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            height: 8px;
            width: 36%;
            border-style: solid;
            border-color: #fff;
        }

        &:before {
            left: 0;
            border-width: 0 0 1px 1px;
        }

        &:after {
            right: 0;
            border-width: 0 1px 1px 0;
        }
    }
}

.FoodSummaryChartDot {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    transition: transform 500ms;

    &__inner {
        font-size: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
        transition: transform 500ms, background 500ms, color 500ms;
        cursor: pointer;

        @include media-max(1200px) {
            width: 30px;
            height: 30px;
            font-size: 10px;
        }

        &.is-solid {
            background: #fff;
            color: #000;
        }

        &.is-dashed {
            background: #0F88FF;
            border: 1px dashed #fff;
            color: #fff;
            box-shadow: 0 0 0 0 #4C7AE8;
        }

        &:hover {
            background: #00E1AF;
            color: #000;
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

@keyframes text-gradient {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 400px;
    }
}