.FoodSummaryChartTooltipMultiline {
    $root: &;
    $background: #fff;
    $accent: #FD8B60;
    $border: #575656;
    $shift: 35px;

    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    transition: transform 300ms;

    &__inner {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        min-width: 50px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.16);
        transform: translate(-50%, -50%);
        background: $background;
        color: #000;
        padding: 8px;
        transition: transform 300ms;
        border-radius: 8px;

        &.p-top {
            transform: translate(-50%, calc(-100% - #{$shift}));

            &.a-right {
                transform: translate(calc(-100% + #{$shift}), calc(-100% - #{$shift}));
            }
        }

        &.p-bottom {
            transform: translate(-50%, #{$shift});

            &.a-right {
                transform: translate(calc(-100% + #{$shift}), #{$shift});
            }
        }
    }

    &__arrow {
        $width: 10px;
        $height: 10px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -$width;
        margin-top: -$height;
        border-width: $height $width $height $width;
        border-style: solid;
        border-color: transparent;
        transition: transform 300ms, border-color 200ms;

        &.p-top {
            border-top-color: $background;
            transform: translateY(-#{$shift - $height + 2px});
        }

        &.p-bottom {
            border-bottom-color: $background;
            transform: translateY(#{$shift - $height + 2px});
        }
    }

    &.is-hidden {
        display: none;
    }

    &__header {
        margin-bottom: 2px;
        font-weight: 500;
    }

    &__cell {
        width: 50px;
        text-align: center;

        &:first-child {
            text-align: right;
            width: 100px;
            padding-right: 8px;
        }


        &.font-small {
            font-size: 9px;
            line-height: 9px;
            height: 10px;
            margin: 0;
            text-align: center;
        }

        &.span-1 {
            width: 50px;
        }

        &.span-2 {
            width: 100px;
        }

        &.span-3 {
            width: 150px;
        }
    }

    &__row {
        display: flex;
        margin-bottom: 1px;
        height: 18px;
        color: #000;

        &.height-s {
            height: 10px;
        }

        &.is-active {
            background: #EAEBED;
            font-weight: 500;
        }
    }

    &__separator {
        width: 0;
        height: 100%;
        border-left: 1px dashed rgba(#000, 0.5);
    }
}
