@import "@styles";

.FoodTabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-right: 40px;
    padding-left: 40px;
    gap: 10px;

    &__item {
        font-size: 12px;
        font-weight: 300;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        transition: font-weight 300ms;
        white-space: nowrap;

        @include media-max(1250px) {
            font-size: 10px;
        }


        @include media-max(1100px) {
            font-size: 8px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            height: 0;
            background: currentColor;
        }

        &.is-active {
            font-weight: 700;

            &:after {
                height: 2px;
                transition: height 300ms;
            }
        }
    }
}