@import "@styles";

.Food {
    $width: 1700px;
    min-width: 1024px;
    $padding-block: 30px;
    $padding-inline: 60px;
    padding: $padding-block $padding-inline 2 * $padding-block;
    max-width: $width + 2 * $padding-inline;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;

    @include media-max(1250px) {
        padding: 20px 20px 2 * 20px;
    }
}